<template>
	<div>
		<h3
			style="
				padding: 16px 16px 0;
				background: #fff;
				font-weight: bold;
				color: #565454;
			"
		>
			所在项目：{{ $route.query.projectName }}
		</h3>
		<Document
			:fileOpFunc="fileOpFunc"
			:defaultDoc="defaultDoc"
			:previewUrl="previewUrl"
			:downloadUrl="downloadUrl"
			:multiple="true"
			:projectId="$route.params.projectId"
			:tableHeight="dataGridHeight"
		></Document>
	</div>
</template>

<script>
import { mapState } from "vuex"
import Document from "@/components/BusinessComponents/Document/Index.vue"
import api from "@/api/project/geneticDoc"

const {
	apiGetPage,
	deleteFile,
	addFile,
	replaceFile,
	getLogs,
	toggleFile,
	uploadFile,
	apiGetPermission,
	apiSetPermission,
	apiGetFormHtml,
	packageFile,
	getTreeList,
	moveFile,
	sortFile,
	emptyFile
} = api

export default {
	name: "genetic",
	components: {
		Document
	},
	data() {
		return {
			// 处理文件的方法（上传，新增，删除，替换，配置权限）
			fileOpFunc: {
				apiGetPage,
				deleteFile,
				addFile,
				replaceFile,
				getLogs,
				toggleFile,
				uploadFile,
				apiGetPermission,
				apiSetPermission,
				apiGetFormHtml,
				packageFile,
				getTreeList,
				moveFile,
				sortFile,
				emptyFile
			},
			// 默认的文件类型、文件id、文件名称
			defaultDoc: {
				id: "6",
				name: "遗传办文档库"
			},
			previewUrl: `${this.$baseUrl}/project/${this.$route.params.projectId}/genetic/docfile`, // 预览路径
			downloadUrl: `${this.$baseUrl}/project/${this.$route.params.projectId}/genetic/docfile` // 下载路径
		}
	},
	computed: {
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 200
			}
		})
	}
}
</script>
<style lang="less"></style>
